import styled from '@emotion/styled';
import { defaultContainerSize, mq } from '../../mixins';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  ${defaultContainerSize};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
`;

export const ActionButton = styled.a`
  cursor: pointer;
  margin-top: 2.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  border-radius: 4rem;
  padding: 0.75rem 2.5rem;
  color: ${({ theme }) => theme.colors.core_white};
  box-shadow: inset 0 0 0 0.0625rem ${({ theme }) => theme.colors.core_white};
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.core_black};
  position: relative;
  transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;

  &:before {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4rem;
    background: linear-gradient(
      88.48deg,
      #0f4ed7 0%,
      #684b9f 59.57%,
      #d2485c 98.61%
    );
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  ${mq.small(css`
    margin-top: 3rem;
  `)};
`;

export const ErrorMessage = styled.div`
  margin: 2.5rem 0 0 0;
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${mq.small(css`
    margin-top: 6rem;
  `)};

  ${mq.xLarge(css`
    display: block;
    text-align: center;
  `)};
`;

export const ErrorSpace = styled.div`
  display: none;

  ${mq.xLarge(css`
    display: inline;
  `)};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.core_white};
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
  flex: 0 0 100%;
  text-align: center;

  ${mq.small(css`
    font-size: 1.875rem;
    line-height: 3rem;
  `)};

  ${mq.xLarge(css`
    width: auto;
    display: inline;
  `)};
`;
