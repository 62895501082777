import Link from 'next/link';
import { GetStaticProps, GetStaticPropsContext } from 'next';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import NotFoundImage from 'components/images/NotFoundImage';
import { apolloClient, getPageQuery } from 'utils/apolloClient';
import { getCurrentLocale } from 'utils/getCurrentLocale';
import { ICookiesQueryData } from 'components/core/Cookies/interfaces';
import { NOT_FOUND_QUERY } from '../graphql/queries';
import { Routes } from '../models/enums/routes';
import { IHeaderQueryData } from '../graphql/model/query/Header';
import {
  ActionButton,
  ErrorMessage,
  Text,
  Wrapper,
} from '../styles/pages/styled/error.styles';

interface IPageProps {
  data: {
    header: IHeaderQueryData;
    cookies: ICookiesQueryData;
    page: {
      title: Array<RichTextBlock>;
      action: Array<RichTextBlock>;
    };
  };
}

const NotFound = ({ data: { page } }: IPageProps) => {
  return (
    <Wrapper>
      <NotFoundImage />
      <ErrorMessage>
        <Text>{RichText.asText(page.title)}</Text>
      </ErrorMessage>
      <Link href={Routes.Home} legacyBehavior>
        <ActionButton>{RichText.asText(page.action)}</ActionButton>
      </Link>
    </Wrapper>
  );
};

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const currentLanguage: string = getCurrentLocale(context);

  const response = await apolloClient.query(
    getPageQuery(
      {
        pageLang: currentLanguage,
      },
      'career_query',
      NOT_FOUND_QUERY
    )
  );

  return {
    props: {
      data: {
        header: response.data.allHeaders.edges[0].node,
        cookies: response.data.allCookiess.edges[0].node,
        page: response.data.allNot_found_pages.edges[0].node,
        seo: {
          seo_title: '404',
          seo_description: '404',
        },
      },
    },
  };
};

export default NotFound;
