import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from 'styles/mixins';

export const Image = styled.svg`
  margin-top: 4rem;
  flex: 0 0 100%;

  ${mq.small(css`
    margin-top: 8rem;
  `)};
`;
